import { Injectable, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import { Browser } from '@capacitor/browser';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  interval,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { UserResponseDto } from '../../../../aok-backend/src/user/dto/create-user.dto';
import { env } from '../../environments/environment';
import { AokUserService } from './aok-user/aok-user.service';
import { GetStreamService } from './get-stream/get-stream.service';

export interface PersonalInfo {
  id?: string;
  displayName?: string;
  bio?: string;
  fullName?: string;
  dob?: string;
  verifiedAt?: string;
  verifyStatus?: string;
  verifiedIdentityDocument?: string;
  image?: string;
  email?: string;
  privateFullName?: boolean,
  privateDateOfBirth?: boolean,
  canBeFoundByEmail?: boolean,
  readReceipts?: boolean,
  typingIndicator?: boolean,
  preferencesId?: string
}

export enum LoginStatusEnum {
  LOADING = 'LOADING',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
}

// noinspection JSIgnoredPromiseFromCall
@Injectable({
  providedIn: 'root',
})
export class AokAuthService {
  // authStatusSubject is needed for idVerify Guard, we need to block it's execution using filter operator
  // until the auth process has finished to avoid racing condition with homeGuard
  private authStatusSubject: BehaviorSubject<LoginStatusEnum> = new BehaviorSubject<LoginStatusEnum>(
    LoginStatusEnum.LOADING,
  );

  private refreshInMsSubject: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  private aokUserSubject: BehaviorSubject<UserResponseDto | null | undefined> = new BehaviorSubject<
    UserResponseDto | null | undefined
  >(null);
  public aokUser$: Observable<UserResponseDto | null | undefined> = this.aokUserSubject
    .asObservable()
    .pipe(shareReplay(1));
  public user$: Observable<User | null | undefined>;
  public userProfile$: Observable<PersonalInfo>;

  public readonly authStatus$: Observable<LoginStatusEnum> = this.authStatusSubject.asObservable();
  private intervalSubscription?: Subscription;

  constructor(
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly userService: AokUserService,
    private getStreamService: GetStreamService,
    private destroyRef: DestroyRef,
  ) {
    this.user$ = this.auth.user$;
    this.initializeAuthState$().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    this.userProfile$ = this.userProfile();
  }

  logout() {
    firstValueFrom(
      this.auth.logout({
        logoutParams: {
          returnTo: env.auth0Callback,
        },
        async openUrl(url: string) {
          return Browser.open({ url, windowName: '_self' });
        },
      }),
    );
    this.router.navigate(['/home']);
  }

  login() {
    console.log('--- login() ---');
    return firstValueFrom(
      this.auth.loginWithRedirect({
        async openUrl(url: string) {
          return Browser.open({ url, windowName: '_self' });
        },
        appState: { target: "/success/You're signed in/home" },
      }),
    );
  }

  private initializeAuthState$(): Observable<UserResponseDto | null | undefined> {
    console.log('initializeAuthState()');
    return this.user$.pipe(
      distinctUntilChanged(),
      filter(Boolean),
      switchMap((user) => {
        return this.userService.getUserByAuth0(user.sub!).pipe(
          switchMap((aokUser) => {
            if (!aokUser?.id) {
              return this.userService.createUserFromAuth0(user).pipe(
                tap((newUser) => {
                  this.aokUserSubject.next(newUser);
                  this.authStatusSubject.next(LoginStatusEnum.FINISHED);
                  console.log('✅✅ 🚨🚨 NEW aokUser Created: ', newUser);
                }),
              );
            }
            this.aokUserSubject.next(aokUser);
            this.authStatusSubject.next(LoginStatusEnum.FINISHED);
            console.log('aokUSer ----> ', aokUser)
            return of(aokUser);
          }),
          tap(async (aokUser) => {
            const authPhoto = (await firstValueFrom(this.user$))?.picture;
            this.getStreamService.initStream(aokUser, authPhoto, aokUser.streamIoToken);
          }),
          catchError((error) => {
            console.error('Error on login --> ', error);
            this.aokUserSubject.next(null);
            this.authStatusSubject.next(LoginStatusEnum.FAILED);
            return of(null);
          }),
        );
      }),
      shareReplay(1), // Share last value as we dont want to do HTTP call on every subscribe
    );
  }

  async isAuthenticated() {
    const authedUser = await firstValueFrom(this.auth.isAuthenticated$);
    return authedUser;
  }

  getUser(): Promise<User | null | undefined> {
    return firstValueFrom(this.auth.user$);
  }

  async isEmailVerified() {
    const user = await firstValueFrom(this.auth.user$);
    return user?.email_verified ?? false;
  }

  public userProfile(): Observable<PersonalInfo> {
    return combineLatest([this.aokUser$.pipe(startWith(null)), this.user$.pipe(startWith(null))]).pipe(
      filter(Boolean),
      map(([aokUser, oAuthUser]: [UserResponseDto | null | undefined, User | null | undefined]) => {
        return {
          id: aokUser?.id,
          displayName: aokUser?.displayName,
          bio: aokUser?.bio,
          fullName: aokUser?.idVerify?.fullName,
          dob: aokUser?.idVerify?.dateOfBirth,
          verifiedAt: aokUser?.idVerify?.completedAt,
          verifyStatus: aokUser?.idVerify?.status,
          verifiedIdentityDocument: aokUser?.idVerify?.type,
          image: aokUser?.profilePhotoUrl || oAuthUser?.picture,
          email: aokUser?.email,
          privateFullName: aokUser?.preferences?.privateFullName,
          privateDateOfBirth: aokUser?.preferences?.privateDateOfBirth,
          canBeFoundByEmail: aokUser?.preferences?.canBeFoundByEmail,
          readReceipts: aokUser?.preferences?.readReceipts,
          typingIndicator: aokUser?.preferences?.typingIndicator,
          preferencesId: aokUser?.preferences?.id
        };
      }),
    );
  }

  public startPollingInMs(intervalMs: number | undefined): void {
    this.refreshInMsSubject.next(intervalMs);
    this.startPollingAokUser(intervalMs);
  }

  private startPollingAokUser(intervalMs: number | undefined): void {
    console.log(`Start polling user information every ${intervalMs} milliseconds`);
    this.intervalSubscription = this.refreshInMsSubject
      .asObservable()
      .pipe(
        filter(Boolean),
        switchMap(() => {
          return interval(intervalMs).pipe(
            tap(() => console.log('Polling user information')),
            switchMap(() => this.initializeAuthState$()),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  public stopPolling(): void {
    console.log('Stopping polling user information');
    this.intervalSubscription?.unsubscribe?.();
    this.intervalSubscription = undefined;
  }

  public getLatestUserInformation(): Observable<void> {
    return this.aokUser$.pipe(
      switchMap((aokUser) => this.userService.getUserById(aokUser?.id!)),
      map((user) => this.aokUserSubject.next(user)),
      switchMap(() => of(void 0)),
      takeUntilDestroyed(this.destroyRef),
    );
  }
}
